import { acceptHMRUpdate, defineStore } from 'pinia';
import { SelectedProductType, UserRole, UserState, AppointedClientType } from '@/types/user';
import {
  removeLocalUserState,
  setLocalUserState,
  getLocalUserState,
} from '@/utils/userStorePersist';
import { userRoles, frontendRolesToBackendRoles } from '@/config/user';
import { initMainNavItems, updateModulesGroups } from '@/utils/composable/navigation';
import { ClientCompany, ClientCompanyClientView } from '@/types/navigation';
import { MAIN_ROUTES } from '@/config/constants/routes';
import getUserFullName from '@/utils/getUserFullName';

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    accessToken: '',
    mercureToken: '',
    ...getLocalUserState(),
    savedUrl: {
      pathname: '',
      search: '',
      hash: '',
    },
  }),

  getters: {
    userProfile(state: UserState) {
      const {
        id,
        firstName,
        lastName,
        middleName,
        phoneNumber,
        email,
        createdAt,
        clientCompanyName,
        accessToken,
        roles,
        clientCompanyId,
      } = state;
      return {
        id,
        fullName: getUserFullName(firstName || '', lastName || ' ', middleName || ''),
        firstName,
        lastName,
        middleName,
        phoneNumber,
        email,
        createdAt,
        clientCompanyName,
        accessToken,
        roles,
        clientCompanyId,
      };
    },
    isUserLoggedIn(state): boolean {
      return !!state.id;
    },
    getSquidexToken(state): string | undefined {
      return state.squidexToken;
    },
    getAllUserRoles(state: UserState): string[] | null | undefined {
      return state.roles;
    },
    getUserRole(state: UserState): UserRole | null {
      const stateUserRoles = state.roles;
      const token = state.accessToken;

      if (!token) return null;

      if (stateUserRoles && stateUserRoles.length === 0) return null;

      if (
        stateUserRoles?.includes(frontendRolesToBackendRoles[UserRole.ROLE_COMPANY_REPRESENTATIVE])
      ) {
        return userRoles.ROLE_COMPANY_REPRESENTATIVE;
      }
      if (
        stateUserRoles?.includes(frontendRolesToBackendRoles[UserRole.ROLE_SUSTAINABILITY_ADVISOR])
      ) {
        return userRoles.ROLE_SUSTAINABILITY_ADVISOR;
      }
      if (stateUserRoles?.includes(frontendRolesToBackendRoles[UserRole.ROLE_BUSINESS_SUPPORT])) {
        return userRoles.ROLE_BUSINESS_SUPPORT;
      }
      if (stateUserRoles?.includes(frontendRolesToBackendRoles[UserRole.ROLE_API_USER])) {
        return userRoles.ROLE_API_USER;
      }

      return null;
    },
    isAPIUserRole(): boolean {
      return this.getUserRole === userRoles.ROLE_API_USER;
    },
    isClientRole(): boolean {
      return this.getUserRole === userRoles.ROLE_COMPANY_REPRESENTATIVE;
    },
    isBSuppRole(): boolean {
      return this.getUserRole === userRoles.ROLE_BUSINESS_SUPPORT;
    },
    isBSuppRoleInClientView(): boolean {
      return this.getAllUserRoles?.[1] === 'ROLE_BUSINESS_SUPPORT';
    },
    isSARole(): boolean {
      return this.getUserRole === userRoles.ROLE_SUSTAINABILITY_ADVISOR;
    },
    isSARoleInClientView(): boolean {
      return this.getAllUserRoles?.[1] === 'ROLE_SUSTAINABILITY_ADVISOR';
    },
    isCopywriterRole(state: UserState): boolean | null | undefined {
      return state.copywriterRole;
    },
    // Means MSA role for this product
    isProductManagerRole(): boolean {
      return (
        this.getUserRole === userRoles.ROLE_SUSTAINABILITY_ADVISOR &&
        !!this.selectedProduct?.isProductManager
      );
    },
    isRegularSARole(): boolean {
      return this.isSARole && !this.isProductManagerRole;
    },
    getSelectedProduct(state: UserState): SelectedProductType | null | undefined {
      return state.selectedProduct;
    },
    getAppointedProducts(state: UserState): SelectedProductType[] | null | undefined {
      return state.appointedProducts;
    },
    getAppointedClients(state: UserState): AppointedClientType[] | null | undefined {
      return state.appointedClients;
    },
    getViewType(state: UserState): string | null | undefined {
      return state.viewType;
    },
    isClientView(state: UserState): boolean {
      return state.viewType === 'clientView';
    },
    getViewCompany(state: UserState): ClientCompanyClientView | null | undefined {
      return state.viewCompany;
    },
    getClientCompany(state: UserState): ClientCompany | null | undefined {
      return state.clientCompany;
    },
    getClientCompanyId(state: UserState): string | null | undefined {
      return state.clientCompanyId;
    },
    getActiveTab(state: UserState): string | null | undefined {
      return state.selectedTab;
    },
    userAvatarUrl(state: UserState): string | null | undefined {
      return state.avatar;
    },
    getMercureToken(state: UserState): string | null | undefined {
      return state.mercureToken;
    },
  },
  actions: {
    setUserProfile(partial: Partial<UserState>) {
      this.$patch(partial);
    },
    async login(userInfo: UserState): Promise<void> {
      if (userInfo.accessToken) {
        this.setUserProfile(userInfo);
        setLocalUserState(userInfo);
      }
    },
    resetUserProfile() {
      this.$reset();
    },
    logout() {
      removeLocalUserState();
      this.resetUserProfile();
    },
    setAccessToken(token: string): void {
      this.$patch({
        accessToken: token,
      });
    },
    setSelectedProduct(selectedProduct: SelectedProductType | null): void {
      this.$patch({
        selectedProduct,
      });
      setLocalUserState(this.$state);
      if (this.getUserRole) {
        initMainNavItems(this.getUserRole);
        updateModulesGroups();
      }
    },
    setClientCompany(clientCompany: ClientCompany | null): void {
      this.$patch({
        clientCompany,
      });
      setLocalUserState(this.$state);
    },
    setAppointedProducts(appointedProducts: SelectedProductType[]): void {
      this.$patch({
        appointedProducts,
      });
      setLocalUserState(this.$state);
    },
    setAppointedClients(appointedClients: AppointedClientType[]): void {
      this.$patch({
        appointedClients,
      });
      setLocalUserState(this.$state);
    },
    setViewType(viewType: string): void {
      this.$patch({
        viewType,
      });
      setLocalUserState(this.$state);
    },
    setViewCompany(viewCompany: ClientCompanyClientView | null): void {
      this.$patch({
        viewCompany,
        clientCompanyId: viewCompany?.id || null,
      });
      if (viewCompany) {
        this.addClientUserRole();
        this.replaceAllowedModules(viewCompany.allowedModules);
      } else {
        this.removeClientUserRole();
        this.replaceAllowedModules(undefined);
        this.replaceDefaultDashboard('');
      }
      setLocalUserState(this.$state);
    },
    setCopywriterRole(copywriterRole: boolean): void {
      this.$patch({
        copywriterRole,
      });
      setLocalUserState(this.$state);
    },
    setUserAvatar(avatar: string | null): void {
      this.$patch({
        avatar,
      });
      setLocalUserState(this.$state);
    },
    setActiveTab(activeTab: string | null): void {
      this.$patch({
        selectedTab: activeTab,
      });
      setLocalUserState(this.$state);
    },
    setSquidexToken(squidexToken: string): void {
      this.$patch({
        squidexToken,
      });
      setLocalUserState(this.$state);
    },
    addClientUserRole(): void {
      const { roles } = this.$state;
      const companyRepRole = frontendRolesToBackendRoles[UserRole.ROLE_COMPANY_REPRESENTATIVE];

      if (roles && !roles.includes(companyRepRole)) {
        roles.unshift(companyRepRole);
        this.$patch({ roles });
      }
    },
    removeClientUserRole(): void {
      const { roles } = this.$state;
      const companyRepRole = frontendRolesToBackendRoles[UserRole.ROLE_COMPANY_REPRESENTATIVE];

      if (roles && roles.includes(companyRepRole)) {
        const updatedRoles = roles.filter((role) => role !== companyRepRole);
        this.$patch({ roles: updatedRoles });
      }
    },
    replaceAllowedModules(allowedModules: string[] | undefined): void {
      const { selectedProduct } = this.$state;
      if (selectedProduct) {
        selectedProduct.allowedModules = allowedModules;
        this.setSelectedProduct(selectedProduct);
      }
    },
    replaceDefaultDashboard(defaultDashboardGroup: string): void {
      const { selectedProduct } = this.$state;
      if (selectedProduct) {
        selectedProduct.defaultDashboardGroup = defaultDashboardGroup;
        this.setSelectedProduct(selectedProduct);
      }
    },
    saveUrl() {
      const excludedPaths: string[] = [
        MAIN_ROUTES.AUTH.path,
        MAIN_ROUTES.PRODUCT_SWITCHER.path,
        MAIN_ROUTES.RESET_PASSWORD.path,
        MAIN_ROUTES.INVITE.path,
      ];

      if (!excludedPaths.includes(window.location.pathname)) {
        this.savedUrl = {
          pathname: window.location.pathname,
          search: window.location.search,
          hash: window.location.hash,
        };
      }
    },
    clearUrl() {
      this.savedUrl = {
        pathname: '',
        search: '',
        hash: '',
      };
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
/* example of function for storing isProductManager property into userStore
const checkIsProductManager = (id: string) => {
  const currentProduct = userStore.appointedProducts?.find((product) => product.id === id);
  return currentProduct?.isProductManager ?? false;
} */
